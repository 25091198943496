import React, { FC, useEffect, useState } from 'react'
import { Avatar, Box, Button, Center, ChipX, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router, usePage } from '@inertiajs/react'
import useAuth from '@/Hooks/useAuth'
import IconOff from '@/Icons/IconOff'
import IconDrawerClose from '@/Icons/IconDrawerClose'
import IconDrawerOpen from '@/Icons/IconDrawerOpen'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import {
  IconFilePencil,
  IconListDetails,
  IconReport,
  IconSearch,
  IconSettings,
  IconSpeakerphone,
  IconSubtask,
} from '@tabler/icons-react'
import {
  toggleB2BCommissionSubSidebar,
  toggleB2BSettingSubSidebar,
  toggleSidebar,
  updateShowSearch,
} from '@/Store/globalSlice'
import useMeta from '@/Hooks/useMeta'
import IconHome from '@/Icons/IconHome'
import IconTextDocumentAlt from '@/Icons/IconTextDocumentAlt'
import IconPlus from '@/Icons/IconPlus'
import IconUserSquare from '@/Icons/IconUserSquare'
import { updateShowB2BAddAgentModal } from '@/Store/B2B/b2bAgentSlice'
import useWidthResize from '@/Hooks/useWidthResize'
import IconBox from '@/Icons/IconBox'
import IconDocuments from '@/Icons/IconDocuments'
import { useClickOutside } from '@mantine/hooks'
import CommissionSubSidebar from '@/Components/B2B/CommissionSubSidebar'
import SettingSubSidebar from '@/Components/B2B/SettingSubSidebar'

export const SIDEBAR_ITEMS = [
  {
    type: 'item',
    label: 'Home',
    route: '/b2b/dashboard',
    icon: <IconHome width="18" height="18" viewBox="0 0 16 16" />,
    roles: ['*'],
    features: ['*'],
    permissions: ['*'],
  },
  {
    type: 'item',
    label: 'Course Wiz',
    route: '/b2b/courses',
    icon: <IconBox width="18" height="17" viewBox="0 0 16 17"></IconBox>,
    roles: ['*'],
    features: ['*'],
    permissions: ['*'],
  },
  {
    type: 'divider',
  },
  {
    type: 'item',
    label: 'Leads',
    route: '/b2b/leads',
    icon: <IconTextDocumentAlt width="18" height="19" viewBox="0 0 16 17" />,
    roles: ['*'],
    features: ['*'],
    permissions: ['*'],
  },
  {
    type: 'item',
    label: 'Agents',
    route: '/b2b/agents',
    icon: <IconUserSquare height="18" width="18" viewBox="0 0 17 17"></IconUserSquare>,
    roles: ['*'],
    features: ['*'],
    permissions: ['*'],
  },
  {
    type: 'item',
    label: 'Reports',
    route: '/b2b/reports',
    icon: <IconReport color={XelaColor.Blue3} stroke={1.2} size={22} fill={XelaColor.Blue12}></IconReport>,
    roles: ['*'],
    features: ['*'],
    permissions: ['*'],
  },
  {
    type: 'item',
    label: 'Announcements',
    route: '/b2b/announcements',
    icon: <IconSpeakerphone color={XelaColor.Blue3} stroke={1.2} size={22} fill={XelaColor.Blue12}></IconSpeakerphone>,
    roles: ['*'],
    features: ['*'],
    permissions: ['*'],
  },
  {
    type: 'item',
    label: 'Contracts',
    route: '/b2b/contracts',
    icon: <IconFilePencil color={XelaColor.Blue3} stroke={1.2} size={22} fill={XelaColor.Blue12}></IconFilePencil>,
    roles: ['*'],
    features: ['contracts'],
    permissions: ['b2b_module_manage_contract'],
  },
  {
    type: 'item',
    label: 'Commissions',
    route: '/b2b/commissions',
    icon: <IconDocuments height="18" width="18" viewBox="0 0 16 16"></IconDocuments>,
    roles: ['*'],
    features: ['b2b_commissions'],
    permissions: ['b2b_module_access_commissions'],
  },
  {
    type: 'divider',
    roles: ['*'],
    features: ['*'],
    permissions: ['*'],
  },
  {
    type: 'item',
    label: 'Tasks',
    route: '/b2b/tasks',
    icon: <IconListDetails stroke={1.5} color={XelaColor.Blue3}></IconListDetails>,
    roles: ['*'],
    features: ['*'],
    permissions: ['*'],
  },
  {
    type: 'item',
    label: 'Follow Ups',
    route: '/b2b/follow-ups',
    icon: <IconSubtask stroke={1.5} color={XelaColor.Blue3}></IconSubtask>,
    roles: ['*'],
    features: ['*'],
    permissions: ['*'],
  },
  {
    type: 'grow',
  },
  {
    type: 'item',
    label: 'Settings',
    route: '/b2b/settings/b2b-courses',
    icon: <IconSettings stroke={1.5} color={XelaColor.Blue3}></IconSettings>,
    roles: ['*'],
    features: ['*'],
    permissions: ['b2b_module_manage_course_settings', 'b2b_module_manage_qr_forms'],
  },
]

const B2BSidebar: FC = () => {
  const dimension = useWidthResize()
  const dispatch = useDispatch()
  const globalStore = useSelector((state: RootState) => state.global)

  const { url } = usePage()
  const agent = useAuth().user
  const agency = useAuth().agency

  const meta = useMeta()
  const auth = useAuth()

  const [isOpen, setOpen] = useState(true)
  const [showElement, setShowElement] = useState(true)
  const outsideRef = useClickOutside(() => {
    dispatch(toggleB2BCommissionSubSidebar(false))
    dispatch(toggleB2BSettingSubSidebar(false))
  })

  // Check width and perform desired action
  useEffect(() => {
    if (dimension < 1200) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [dimension])

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowElement(true)
      }, 150)
    } else {
      setShowElement(false)
    }
  }, [isOpen])

  useEffect(() => {
    dispatch(toggleSidebar(isOpen))
  }, [isOpen])

  const avatar = useSelector((state: RootState) => state.user.avatar)

  return (
    <div
      ref={outsideRef}
      className="sidebar"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderLeft: `5px solid ${XelaColor.Pink3}`,
        gap: '12px',
        height: '100vh',
        width: isOpen ? '260px' : '100px',
        backgroundColor: XelaColor.White,
        overflowX: !isOpen ? 'visible' : 'hidden',
        overflowY: 'auto',
      }}
    >
      <HStack justifyContent={isOpen ? 'space-between' : 'flex-end'}>
        <VStack spacing="4px">
          <HStack spacing={isOpen ? '20px' : '0px'} justifyContent="center">
            {showElement && (
              <VStack>
                <Typography variant={'sub-caption'} color={XelaColor.Gray3} style={{ fontSize: '10px' }}>
                  Agency
                </Typography>
                <Typography
                  variant={'subheadline'}
                  color={XelaColor.Blue3}
                  style={{
                    width: '150px',
                    wordBreak: 'break-all',
                    maxHeight: '90px',
                    overflowY: 'hidden',
                  }}
                >
                  {agency.agency_name}
                </Typography>
              </VStack>
            )}
            <Center
              className="sidebar-icon-btn"
              onClick={() => {
                setOpen(!isOpen)
              }}
              width="36px"
              height="36px"
            >
              {isOpen ? (
                <IconDrawerClose width="15" height="16" viewBox="0 0 15 16" />
              ) : (
                <IconDrawerOpen width="15" height="16" />
              )}
            </Center>
          </HStack>
        </VStack>
      </HStack>
      <Divider variant="dotted" />
      <Center className="sidebar-icon-btn">
        <HStack spacing="8px">
          {!isOpen && auth.role.permissions.includes('b2b_module_create_new_agent') && (
            <Button
              style={{ padding: '16px' }}
              leftIcon={<IconPlus width={17} height={17} viewBox="0 0 15 15"></IconPlus>}
              variant="secondary"
              onClick={() => {
                dispatch(updateShowB2BAddAgentModal(true))
              }}
            ></Button>
          )}
          {isOpen && (
            <Button
              autoresize={false}
              style={{ padding: '0', width: '70px', height: isOpen ? '48px' : '42px' }}
              leftIcon={<IconSearch size={isOpen ? 24 : 20}></IconSearch>}
              variant="secondary"
              onClick={() => {
                dispatch(updateShowSearch(true))
              }}
            ></Button>
          )}
          {isOpen && showElement && auth.role.permissions.includes('b2b_module_create_new_agent') && (
            <HStack
              bg={XelaColor.Blue3}
              borderRadius="16px"
              spacing="8px"
              height="48px"
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                dispatch(updateShowB2BAddAgentModal(true))
              }}
            >
              <Typography style={{ minWidth: '115px' }} variant="body-small-bold" color={XelaColor.White}>
                Create New Agent
              </Typography>
            </HStack>
          )}
        </HStack>
      </Center>
      <Divider variant="dotted" />
      <VStack spacing="12px" className="grow">
        {SIDEBAR_ITEMS.filter((item) => {
          if (item.type !== 'item') {
            return true
          }

          // Check if user has both permissions and features to perform the action
          if (item.permissions && item.features) {
            // Allow if all permissions match or '*' is present in permissions
            const hasPermission =
              item.permissions.includes('*') ||
              item.permissions.some((permission) => auth.role.permissions.includes(permission))

            // Allow if all features match or '*' is present in features
            const hasFeature =
              item.features.includes('*') ||
              item.features.every((feature) => auth.agency.subscription_features.includes(feature))

            return hasPermission && hasFeature
          }

          return false
        }).map((item, index) => {
          if (item.type === 'divider') {
            return <Divider key={index} variant="dotted" />
          }

          if (item.type === 'grow') {
            return <Box key={index} className="grow"></Box>
          }

          const isActiveCommission = item.route === '/b2b/commissions' && url.startsWith('/b2b/commission/')
          const isActiveSettings = item.route === '/b2b/settings/b2b-courses' && url.startsWith('/b2b/settings/')

          return (
            <HStack
              key={index}
              onClick={() => {
                dispatch(toggleB2BSettingSubSidebar(false))
                dispatch(toggleB2BCommissionSubSidebar(false))
                if (item.route) {
                  if (item.route === '/b2b/commissions') {
                    dispatch(toggleB2BCommissionSubSidebar(!globalStore.isB2BCommissionSubSidebarOpen))
                  } else if (item.route === '/b2b/settings/b2b-courses') {
                    dispatch(toggleB2BSettingSubSidebar(!globalStore.isB2BSettingSubSidebarOpen))
                  } else router.visit(item.route)
                }
              }}
              spacing="12px"
              style={
                item.route && (url.startsWith(item.route) || isActiveCommission || isActiveSettings)
                  ? {
                      padding: '8px',
                      paddingLeft: '16px',
                      backgroundColor: XelaColor.Blue12,
                      borderRadius: '12px',
                      width: '100%',
                    }
                  : {
                      padding: '8px',
                      paddingLeft: '16px',
                    }
              }
              className="sidebar-icon-btn"
            >
              <Center width="18px" height="18px" style={{ minWidth: '18px' }}>
                {item.icon}
              </Center>
              {isOpen && (
                <>
                  <Typography variant="body-small-bold">{item.label}</Typography>
                  {(item.route === '/b2b/tasks' || item.route === '/b2b/follow-ups') && (
                    <ChipX
                      label={
                        item.route === '/b2b/tasks'
                          ? meta.counts.b2bTasks.toString()
                          : meta.counts.b2bFollowUps.toString()
                      }
                      size="small"
                    ></ChipX>
                  )}
                </>
              )}
            </HStack>
          )
        })}
      </VStack>
      <HStack spacing="12px" justifyContent="center">
        <Avatar size="small" image={<img src={avatar || agent.avatar} alt="avatar" />} />
        {isOpen && showElement && (
          <>
            <VStack
              style={{
                minWidth: dimension > 1440 ? '150px' : '130px',
                wordBreak: 'break-all',
              }}
            >
              <Typography variant="body-small-bold" color={XelaColor.Gray2}>
                {agent.name}
              </Typography>
              <Typography variant="caption" color={XelaColor.Gray6}>
                {agent.email}
              </Typography>
            </VStack>
            <Center width="16px" style={{ minWidth: '16px' }} className="sidebar-icon-btn">
              <IconOff
                width="16"
                height="16"
                viewBox="0 0 15 15"
                onClick={() => {
                  router.post('/logout')
                }}
              ></IconOff>
            </Center>
          </>
        )}
      </HStack>
      <CommissionSubSidebar />
      <SettingSubSidebar />
    </div>
  )
}

export default B2BSidebar
